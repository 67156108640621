export default [
  // AUTH START
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/other/auth/v2/Login.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layout: 'full',
      pageTitle: 'Login',
    },
  },

  {
    path: '/forgotpassword',
    name: 'forgot-password',
    component: () => import('@/views/other/auth/v2/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Login',
    },
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/other/auth/v2/Register.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Login',
    },
  },
  {
    path: '/resetpassword',
    name: 'reset-password',
    component: () => import('@/views/other/auth/v2/ResetPassword.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Reset Password',
    },
  },

  {
    path: '/authpassword',
    name: 'auth-password',
    component: () => import('@/views/other/auth/AuthPassword.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Auth Password',
    },
  },
  {
    path: '/notAuthorized',
    name: 'auth-not-authorized',
    component: () => import('@/views/other/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Login',
    },
  },

  // AUTH ENDED
]
