import Vue from 'vue'
import VueRouter from 'vue-router'
// import { isUserLoggedIn } from '@/auth/utils'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import global from './global'
import other from './other'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { home: false, callback: false, name: 'dashboard' } },
    { path: '/', redirect: { name: 'analytic' } },
    ...global,
    ...other,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// eslint-disable-next-line arrow-body-style
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const unprotectedRoute = ['/forgotpassword', '/authpassword', '/resetpassword', '/register']
  if (unprotectedRoute.includes(to.path)){
    return next()
  }
  // console.log(isLoggedIn)
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // // If logged in => not authorized
    // return next({ name: 'auth-not-authorized' })
  }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
