import mock from '@/@fake-db/mock'
// eslint-disable-next-line object-curly-newline

/* eslint-disable global-require */
const data = {
  products: [
    {
      personal:
          {
            image: "",
            name: "Site Zone A",
          },
      action: 'PPE Briefing',
      wbs: '04:21PM',
      planning: {
        startTime: '04:21PM',
        endTime: '05:31PM',
      },
      actual: {
        startTime: '04:30PM',
        endTime: '06:00PM',
      },
      code: 'A12341',
      class: 'light-warning',
    },
    {
      personal:
            {
              image: "",
              name: "Site Zone B",
            },
      action: 'Digging Trenches',
      wbs: '05:30PM',
      planning: {
        startTime: '01:30PM',
        endTime: '05:30PM ',
      },
      actual: {
        startTime: '12:00PM',
        endTime: '06:30PM',
      },
      code: 'A12334',
      class: 'light-warning',
    },
    {
      personal:
              {
                image: "",
                name: "Site Zone C",
              },
      action: 'Site Maintenance',
      wbs: '12:00PM ',
      planning: {
        startTime: '08:00AM',
        endTime: '05:00PM',
      },
      actual: {
        startTime: '10:00AM',
        endTime: '4:00PM',
      },
      code: 'B02334',
      class: 'light-info',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/map/data').reply(() => [200, data])
