import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  recent: [
    {
      s3imageUrl: "http://ec2-13-250-59-131.ap-southeast-1.compute.amazonaws.com/test/powerpoint.ppt",
      originalFileName: "Sample PPT.ppt",
      key: "ddfe17f6-a9aa-4e34-9bd9-0a5220dd8a1d",
      date: "2021-12-31T07:12:14.482Z",
    },
    {
      s3imageUrl: "http://ec2-13-250-59-131.ap-southeast-1.compute.amazonaws.com/test/excel.xls",
      originalFileName: "Sample Excel.xls",
      key: "ddfe17f6-a9aa-4e34-9bd9-0a5220dd8a1d",
      date: "2021-12-31T07:12:14.482Z",
    },
    {
      s3imageUrl: "http://ec2-13-250-59-131.ap-southeast-1.compute.amazonaws.com/test/document.docx",
      originalFileName: "Sample Docs.docx",
      key: "ddfe17f6-a9aa-4e34-9bd9-0a5220dd8a1d",
      date: "2021-12-31T07:12:14.482Z",
    },
    {
      originalFileName: 'Project Maintenance.png',
      date: '8 September 2021',
      type: 'jpg',
      s3imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Petronas_Panorama_II.jpg/1200px-Petronas_Panorama_II.jpg',
    },
    {
      originalFileName: 'Project Maintenance.png',
      date: '8 September 2021',
      type: 'png',
      s3imageUrl: 'https://images.unsplash.com/photo-1515165562839-978bbcf18277?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
  ],
  file: [{
    img: '',
    name: 'On-Site Briefing',
    date: 'Last accessed 3 hours ago',
    type: 'PDF',
  },
  {
    img: '',
    name: 'Project Maintenance',
    date: '8 September 2021',
    type: 'PPT',
  }, {
    img: '',
    name: 'Project List',
    date: '1 September 2021',
    type: 'DOCX',
  },
  {
    img: '',
    name: 'WBS Sample',
    date: '23 August 2021',
    type: 'csv',
  }],
  folder: [{
    img: '',
    name: 'Projects',
    date: 'Last accessed 21 hours ago',
    size: '50.34mb',
  },
  {
    img: '',
    name: 'Design',
    date: 'Last accessed 18 hours ago',
    size: '2.4gb',
  }, {
    img: '',
    name: 'Maintenance',
    date: 'Last accessed 2 days ago',
    size: '75.8mb',
  },
  {
    img: '',
    name: 'Documents',
    date: 'Last accessed 6 days ago',
    size: '10.2mb',
  }],
}
/* eslint-disable global-require */
mock.onGet('/documents/data').reply(() => [200, data])
