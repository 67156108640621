/* eslint-disable indent */
import mock from "@/@fake-db/mock"
/* eslint-disable global-require */
const data = {
        congratulations: {
            name: "John",
            saleToday: "57.6",
        },
        subscribersGained: {
            series: [{
                name: "Subscribers",
                data: [28, 40, 36, 52, 38, 60, 55],
            }],
            analyticsData: {
                subscribers: 92600,
            },
        },
        ordersRecevied: {
            series: [{
                name: "Orders",
                data: [10, 15, 8, 15, 7, 12, 8],
            }],
            analyticsData: {
                orders: 38400,
            },
        },
        avgSessions: {
            sessions: 2700,
            lastDays: ["Last 28 Days", "Last Month", "Last Year"],
            growth: "+5.2%",
            goal: 100000,
            users: 100000,
            retention: 90,
            duration: 1,
            salesBar: {
                series: [{
                    name: "Sessions",
                    data: [75, 125, 225, 175, 125, 75, 25],
                }],
            },
        },
        supportTracker: {
            title: "Support Tracker",
            lastDays: ["Last 28 Days", "Last Month", "Last Year"],
            totalTicket: 163,
            newTicket: 29,
            openTicket: 63,
            responseTime: 1,
            supportTrackerRadialBar: {
                series: [83],
            },
        },
        timeline: {
            step1: {
                title: "12 Invoices have been paid",
                subtitle: "Invoices have been paid to the company.",
                img: require("@/assets/images/icons/json.png"),
                fileName: "data.json",
                duration: "12 min ago",
            },
            step2: {
                title: "Client Meeting",
                subtitle: "Project meeting with john @10:15am",
                avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
                avatarName: "John Doe (Client)",
                occupation: "CEO of Infibeam",
                duration: "45 min ago",
            },
            step3: {
                title: "Create a new project for client",
                subtitle: "Add files to new design folder",
                duration: "2 day ago",
                avatars: [{
                        userImg: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
                        name: "Billy Hopkins",
                    },
                    {
                        userImg: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
                        name: "Amy Carson",
                    },
                    {
                        userImg: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
                        name: "Brandon Miles",
                    },
                    {
                        userImg: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
                        name: "Daisy Weber",
                    },
                    {
                        userImg: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
                        name: "Jenny Looper",
                    },
                ],
            },
            step4: {
                title: "Create a new project for client",
                duration: "5 day ago",
                subtitle: "Add files to new design folder",
            },
        },
        salesChart: {
            series: [{
                    name: "Sales",
                    data: [90, 50, 86, 40, 100, 20],
                },
                {
                    name: "Visit",
                    data: [70, 75, 70, 76, 20, 85],
                },
            ],
        },
        weeklyOverview: {
            listTasks: [{
                    icon: "",
                    taskName: "Piling",
                    series: [59.3],
                    color: "#FF9F43",
                },
                {
                    icon: "",
                    taskName: "Document Prior to CPC",
                    series: [6.1],
                    color: "#28C76F",
                },
                {
                    icon: "",
                    taskName: "Application to CCC",
                    series: [14.6],
                    color: "#28C76F",
                },
                {
                    icon: "",
                    taskName: "Excavation",
                    series: [8.4],
                    color: "#EA5455",
                },
                {
                    icon: "",
                    taskName: "Installing Flashing",
                    series: [9.1],
                    color: "#EA5455",
                },
                {
                    icon: "",
                    taskName: "Finishing works",
                    series: [12.3],
                    color: "#EA5455",
                },
                // {
                //     icon: "",
                //     taskName: "Discussion Preparation",
                //     series: [12.3],
                //     color: "#00A99C",
                // },
            ],
        },
        totalSensors: {
            sensors: [{
                    avatar: require('@/assets/images/icons/green-dot.png'),
                    deviceName: 'Smart Helmet',
                    deviceQuantity: 5,
                },
                {
                    avatar: require('@/assets/images/icons/yellow-dot.png'),
                    deviceName: 'RFID',
                    deviceQuantity: 2,
                },
                {
                    avatar: require('@/assets/images/icons/purple-dot.png'),
                    deviceName: 'CCTV',
                    deviceQuantity: 10,
                },
                {
                    avatar: require('@/assets/images/icons/red-dot2.png'),
                    deviceName: 'GPS Tracker',
                    deviceQuantity: 8,
                },
                {
                    avatar: require('@/assets/images/icons/pink-dot.png'),
                    deviceName: 'Smart Glasses',
                    deviceQuantity: 5,
                },
            ],
        },
        totalStatus: {
            status: [{
                    title: 'Total Active',
                    total: 432,
                    series: [38],
                    description: '68.2%',
                },
                {
                    title: 'Total Inactive',
                    total: 289,
                    series: [38],
                    description: '32.1%',
                },
            ],
        },
        manpower: {
            title: 'Manpower Tracker',
            total: 163,
            inProgress: 30,
            completed: 24,
            totalHours: 120,
            series: [83],
            color: ['#E7555B', '#BD5C91', '#9263CA'],
            status: ['Last 7 days'],
        },
        issues: {
            title: 'Non-Compliance Issues',
            description: 'Total Incidents Occurence Rate',
            totalRate: 72,
            series: [36, 28, 14, 72],
            color: ['#00D4BD', '#8769FF', '#FF9B9E', '#FFE700'],
            type: [{ color: '#FFE700', title: 'Human Error' }, { color: '#00D4BD', title: 'Lack of Proper Training' }, { color: '#8769FF', title: 'Unsafe Methods' }, { color: '#FF9B9E', title: 'Lack of Safety Devices' }],
        },
        budget: {
            total: 'RM 50 Million',
            description: 'Total Cost',
            date: 'Last 12 Months',
            target: '+5.2%',
            barColor: '#7367F0',
            overBudget: 'RM750 Million',
            totalSpend: 'RM32 Million',
            underBudget: 'RM250 Million',
            duration: '19 month',
            lastDays: ['Last 6 Months', 'Last Week', 'Yesterday'],
        },
        onsiteLocationList: {
            title: 'On-Site Location',
            location: [{
                    icon: '',
                    color: '#7367F01F',
                    city: 'Cheras',
                    state: 'Kuala Lumpur',
                    status: 'Inactive',
                    lat: 3.153244,
                    long: 101.703810,
                    currZoom: 15,
                    geometry: {
                        type: 'Point',
                        coordinates: [101.703810, 3.153244],
                    },
                },
                {
                    icon: '',
                    color: '#28C76F1F',
                    city: 'Pengerang',
                    state: 'Johor Bharu',
                    status: 'Active',
                    lat: 1.376466,
                    long: 104.161694,
                    currZoom: 15,
                    geometry: {
                        type: 'Point',
                        coordinates: [104.161694, 1.376466],
                    },
                },
                {
                    icon: '',
                    color: '#EA54551FF',
                    city: 'Shah Alam',
                    state: 'Selangor',
                    status: 'Active',
                    lat: 3.074353,
                    long: 101.517739,
                    currZoom: 15,
                    geometry: {
                        type: 'Point',
                        coordinates: [101.517739, 3.074353],
                    },
                },
                {
                    icon: '',
                    color: '#FF9F431F',
                    city: 'Kuala Terengganu',
                    state: 'Terengganu',
                    status: 'Inactive',
                    lat: 5.3288028,
                    long: 103.1341669,
                    currZoom: 15,
                    geometry: {
                        type: 'Point',
                        coordinates: [103.1341669, 5.3288028],
                    },
                },
                {
                    icon: '',
                    color: '#FDEAEB',
                    city: 'Klang',
                    state: 'Selangor',
                    status: 'Active',
                    lat: 3.0429652,
                    long: 101.3706163,
                    currZoom: 15,
                    geometry: {
                        type: 'Point',
                        coordinates: [101.3706163, 3.0429652],
                    },
                },
            ],
        },
        manhourdataOverview: {
            type: [{
                series: [155],
                color: "#28C76F",
            }],
        },
        dataOverview: {
            type: [{
                    name: "Cost",
                    total: "83%",
                    planning: "RM180 Million",
                    actual: "RM75 Million",
                    series: [41.7],
                    color: "#28C76F",
                    concern: 'Cost Budget (RM)',
                    categories: ['June ‘19', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan ‘20', 'Feb', 'Mar', 'Apr', 'May'],
                    dataPlot: [{
                            name: 'Total',
                            data: [3.0, 7.2, 7.1, 7.7, 10.2, 3.3, 6.5, 8.3, 6.9, 5.5, 4.4, 4.9],
                        },
                        {
                            name: 'Plan',
                            data: [5, 9, 8, 9.8, 12.5, 6.8, 8.0, 9, 7.5, 8, 8.5, 7.9],
                        },
                    ],
                    dataPlotScurve: [{
                        name: 'Actual',
                        data: [1.7, 5.7, 9.6, 13.9, 19.6, 21.4, 25, 29.6, 33.4, 36.5, 38.9, 41.7],
                    },
                    {
                        name: 'Plan',
                        data: [2.8, 7.8, 12.2, 17.7, 24.4, 28.4, 32.8, 37.8, 42, 46.4, 51.2, 55.6],
                    },
                    {
                        name: 'Total',
                        data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
                    },
                ],
                },
                {
                    name: "Time",
                    total: "100%",
                    planning: "70%",
                    actual: "65%",
                    series: [65],
                    color: "#00CFE8",
                    concern: 'Time',
                    categories: ['June ‘19', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan ‘20', 'Feb', 'Mar', 'Apr', 'May'],
                    // categories: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    dataPlot: [{
                            name: 'Actual',
                            data: [0, 70, 72, 74, 76, 78, 80, 84, 86, 90, 92, 96],
                        },
                        {
                            name: 'Plan',
                            data: [0, 72, 74, 76, 78, 80, 82, 90, 92, 96, 98, 99],
                        },
                    ],
                    dataPlotScurve: [{
                        name: 'Total',
                        data: [0, 5, 3, 18, 23, 27, 34, 37, 43, 55, 59, 65],
                    },
                    {
                        name: 'Plan',
                        data: [0, 7, 15, 22, 28, 31, 38, 41, 48, 59, 66, 70],
                    },
                ],
                },
                {
                    name: "Resources",
                    total: "92.91%",
                    planning: "1,000,000 Hours",
                    actual: "600,000 Hours",
                    series: [60],
                    color: "#FF9F43",
                    concern: 'Resources',
                    categories: ['June ‘19', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan ‘20', 'Feb', 'Mar', 'Apr', 'May'],
                    dataPlot: [{
                        name: 'Actual',
                        data: [30000, 42000, 51000, 67000, 82000, 33000, 65000, 53000, 29000, 55000, 44000, 49000],
                    },
                    {
                        name: 'Plan',
                        data: [50000, 90000, 80000, 98000, 125000, 68000, 80000, 90000, 75000, 80000, 85000, 79000],
                    },
                ],
                dataPlotScurve: [{
                    name: 'Total',
                    data: [5, 14, 22, 31.8, 44.3, 51.1, 59.1, 68.1, 75.6, 83.6, 92.1, 100],
                },
                {
                    name: 'Plan',
                    data: [3, 7.2, 12.3, 19, 27.2, 30.5, 37, 42.3, 45.2, 50.7, 55.1, 60],
                },
            ],
                },
                {
                    name: "Quality",
                    total: "0.44%",
                    planning: "1500",
                    actual: "1300",
                    series: [86.67],
                    color: "#A41F03",
                    concern: 'Quality Overview',
                    categories: ['June ‘19', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan ‘20', 'Feb', 'Mar', 'Apr', 'May'],
                    dataPlot: [{
                        name: 'Total',
                        data: [50, 90, 80, 98, 125, 132, 151, 115, 143, 160, 180, 176],
                    },
                    {
                        name: 'Plan',
                        data: [35, 72, 68, 81, 114, 124, 134, 108, 121, 134, 161, 148],
                    },
                ],
                dataPlotScurve: [{
                    name: 'Resolve',
                    data: [3.3, 9.3, 14.7, 21.2, 29.5, 38.3, 48.4, 56.7, 65.6, 76.3, 88.3, 100],
                },
                {
                    name: 'Total',
                    data: [2.3, 7.1, 11.7, 17.1, 24.7, 32.9, 40.1, 47.3, 57.1, 66.1, 76.8, 86.7],
                },
            ],
                },
                {
                    name: "Safety",
                    total: "83%",
                    planning: "204",
                    actual: "125",
                    series: [100.00],
                    color: "#FF9F43",
                    concern: 'Safety Cases',
                    categories: ['LTI', 'Fatal Accident', 'Minor Injury', 'Tier 1 Safety', 'Illness (Non Covid - 19)', 'Covid 19 Close Contact', 'Covid 19 Confirmed Contact '],
                    dataPlot: [230, 400, 800, 230, 210, 550, 150],
                },
            ],
        },
        listProjectTeam: {
            projectTeam: [{
                    team: {
                        icon: "",
                        name: "Welder Team A",
                    },
                    planningHours: 70,
                    actualHours: 50,
                    startDate: "1.7.2021",
                    endDate: "1.8.2021",
                    status: "Overdue",
                },
                {
                    team: {
                        icon: "",
                        name: "Installer Team B",
                    },

                    planningHours: 60,
                    actualHours: 80,
                    startDate: "30.10.2021",
                    endDate: "30.10.2021",
                    status: "Completed",
                },
                {
                    team: {
                        icon: "",
                        name: "Project Manager",
                    },

                    planningHours: 40,
                    actualHours: 38,
                    startDate: "31.8.2021",
                    endDate: "31.8.2021",
                    status: "Overdue",
                },
                {
                    team: {
                        icon: "",
                        name: "Project Engineer",
                    },

                    planningHours: 50,
                    actualHours: 50,
                    startDate: "30.7.2021",
                    endDate: "30.7.2021",
                    status: "Overdue",
                },
                {
                    team: {
                        icon: "",
                        name: "Ground Inspector",
                    },

                    planningHours: 40,
                    actualHours: 20,
                    startDate: "15.12.2021",
                    endDate: "15.12.2021",
                    status: "Completed",
                },
                {
                    team: {
                        icon: "",
                        name: "Site Supervisor",
                    },

                    planningHours: 70,
                    actualHours: 60,
                    startDate: "15.11.2021",
                    endDate: "15.11.2021",
                    status: "Completed",
                },
                {
                    team: {
                        icon: "",
                        name: "Mechanical Team A",
                    },

                    planningHours: 55,
                    actualHours: 70,
                    startDate: "30.11.2021",
                    endDate: "30.11.2021",
                    status: "Completed",
                },
            ],
        },
        listTeamMembers: {
            members: [{
                    personal: {
                        image: "",
                        name: "Myra Foster",
                        email: "wiwlirug@talgajup.co.uk",
                    },

                    role: 'Welder Group A',
                    user_id: '11203-PETRO-12',
                    remarks: 'Inappropiate choice',
                    status: 'Active',
                },
                {
                    personal: {
                        image: "",
                        name: "Sweden",
                        email: "nilrebfo@juka.com",
                    },

                    role: 'Installer Group B',
                    user_id: '21201-PETRO-12',
                    remarks: 'Poor ground condition',
                    status: 'Pending',
                },
                {
                    personal: {
                        image: "",
                        name: "Matilda Robertson",
                        email: "besew@roer.edu",
                    },

                    role: 'Supervisor',
                    user_id: '81321-PETRO-12',
                    remarks: 'Conduct a safety a…',
                    status: 'Inactive',
                },
                {
                    personal: {
                        image: "",
                        name: "French Southern",
                        email: "vo@ri.io",
                    },

                    role: 'Project Manager',
                    user_id: '22110-PETRO-12',
                    remarks: 'Design any incident',
                    status: 'Inactive',
                },
                {
                    personal: {
                        image: "",
                        name: "Martha Barnes",
                        email: "jufuva@vauldas.org",
                    },

                    role: 'Installer Group C',
                    user_id: '10212-PETRO-12',
                    remarks: 'Analyze HSE trend...',
                    status: 'Pending',
                },
                {
                    personal: {
                        image: "",
                        name: "Monaco",
                        email: "kufuti@kiuco.gov",
                    },

                    role: 'Maintainer',
                    user_id: '11203-PETRO-12',
                    remarks: 'Conduct self-assesment',
                    status: 'Active',
                },
                {
                    personal: {
                        image: "",
                        name: "Minnie Hoffman",
                        email: "ob@uhamaka.co.uk",
                    },

                    role: 'Supervisor',
                    user_id: '11203-PETRO-12',
                    remarks: 'HSE checking & co...',
                    status: 'Active',
                },
                {
                    personal: {
                        image: "",
                        name: "Lawrence Walton",
                        email: "pi@dufrorep.net",
                    },

                    role: 'Welder Group B',
                    user_id: '00201-PETRO-12',
                    remarks: 'Action progress u…',
                    status: 'Inactive',
                },
                {
                    personal: {
                        image: "",
                        name: "Mable Fernandez",
                        email: "ho@inel.gov",
                    },
                    role: 'Site Manager',
                    user_id: '62101-PETRO-12',
                    remarks: 'Enforce manpow...',
                    status: 'Active',
                },
                {
                    personal: {
                        image: "",
                        name: "Gregory Maldonado",
                        email: "kegbep@pardegeho.net",
                    },
                    role: 'Admin',
                    user_id: '32201-PETRO-12',
                    remarks: 'Safety action pla...',
                    status: 'Inactive',
                },
            ],
        },
        listManHours: {
            manHours: [{
                    personal: {
                        image: "",
                        employee_id: "Corey Taylor",
                        email: "57238521C",
                    },

                    start_time: '07:00AM',
                    end_time: '04:21PM',
                    durationA: '1 Hour',
                    durationB: '23 Minutes',
                },
                {
                    personal: {
                        image: "",
                        employee_id: "Sid Wilson",
                        email: "57238521C",
                    },

                    start_time: '08:30AM',
                    end_time: '05:30PM',
                    durationA: '1 Hour 30 Minutes',
                    durationB: '41 Minutes',
                },
                {
                    personal: {
                        image: "",
                        employee_id: "Chris Fehn",
                        email: "57238521C",
                    },

                    start_time: '08:00AM',
                    end_time: '12:00PM',
                    durationA: 'Unavailable',
                    durationB: 'Unavailable',
                },
                {
                    personal: {
                        image: "",
                        employee_id: "Shawn Crahan",
                        email: "57238521C",
                    },

                    start_time: '09:25AM',
                    end_time: '06:25PM',
                    durationA: '30 Minutes',
                    durationB: '15 Minutes',
                },
                {
                    personal: {
                        image: "",
                        employee_id: "Joey Jordison",
                        email: "57238521C",
                    },

                    start_time: '08:12AM',
                    end_time: '05:12PM',
                    durationA: '24 Minutes',
                    durationB: '5 Minutes',
                },
                {
                    personal: {
                        image: "",
                        employee_id: "Craig Jones",
                        email: "57238521C",
                    },

                    start_time: '07:30AM',
                    end_time: '04:21PM',
                    durationA: '35 Minutes',
                    durationB: '23 Minutes',
                },
                {
                    personal: {
                        image: "",
                        employee_id: "Paul Gray",
                        email: "57238521C",
                    },

                    start_time: '08:31AM',
                    end_time: '03:55PM',
                    durationA: '50 Minutes',
                    durationB: '10 Minutes',
                },
            ],
        },
        appDesign: {
            date: "03 Sep, 20",
            title: "App design",
            subtitle: "You can Find Only Post and Quotes Related to IOS like ipad app design, iphone app design",
            teams: [
                { name: "Figma", color: "light-warning" },
                { name: "Wireframe", color: "light-primary" },
            ],
            members: [{
                    img: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
                    color: "primary",
                },
                { text: "PI", color: "light-danger" },
                {
                    img: require("@/assets/images/portrait/small/avatar-s-14.jpg"),
                    color: "primary",
                },
                {
                    img: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
                    color: "primary",
                },
                { text: "AL", color: "light-secondary" },
            ],
            planing: [
                { title: "Due Date", subtitle: "12 Apr, 21" },
                { title: "Budget", subtitle: "$49251.91" },
                { title: "Cost", subtitle: "$840.99" },
            ],
        },
    }
    /* eslint-disable global-require */
    // eslint-disable-next-line eol-last
mock.onGet("/analytics/data").reply(() => [200, data])
