export const isUserLoggedIn = () => {
  // return localStorage.getItem('userData-Core') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  const userData = localStorage.getItem('userData-Core')
  if (userData === null || userData === ""){
    return false
  }

  return true

  // return localStorage.getItem('userData-Core')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData-Core'))

export const getHomeRouteForLoggedInUser = userRole => {
  // if (userRole === 'Admin' || userRole === 'Super Admin') return '/'
  if (userRole.includes(2)) return '/'
  // if (userRole === 'client') return { name: 'access-control' }
  return { name: 'login' }
}
