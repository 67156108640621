export default [
  {
    path: '/editDashboard',
    name: 'edit-dashboard',
    // component: () => import('@/views/hr/dashboard/analytic/component/editDashboard.vue'),
    component: () => import('@/views/pages/dashboard/analytic/component/EditDashboard1.vue'),
    meta: {
      navActiveLink: 'analytic',
      // contentRenderer: 'sidebar-left-detached',
      // contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/analytic',
    name: 'analytic',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/pages/dashboard/analytic/Analytics.vue'),
    meta: {
    },
  },
  {
    path: '/map-view',
    name: 'map-view',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/pages/dashboard/map/Map_View.vue'),
    meta: {
      layout: 'semi',
    },
    // component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },
  // DASHBOARD ENDED
  {
    path: '/drone-flight',
    name: 'drone-flight',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },
  {
    path: '/cctv',
    name: 'cctv',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },
  {
    path: '/file-manager',
    name: 'file-manager',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },

  {
    path: '/calendar',
    name: 'calendar',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/pages/planner/calendar/Calendar.vue'),
  },
  {
    path: '/gantt-chart',
    name: 'gantt-chart',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/pages/planner/chart/Gantt.vue'),
    // component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },
  {
    path: '/kanban',
    name: 'kanban',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },
  {
    path: '/csv',
    name: 'csv',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },
  {
    path: '/model-overview',
    name: 'model-overview',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },
  {
    path: '/heath-and-maintenance',
    name: 'health-and-maintenance',
    // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
    component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  },

  // PLANNER TASK START
  {
    path: '/task',
    name: 'apps-todo',
    component: () => import('@/views/pages/planner/task/Task.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },

  
  {
    path: '/task/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/pages/planner/task/Task.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/task/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/pages/planner/task/Task.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },
  // PLANNER TASK ENDED
  
 

  
 

  // DOC PREVIEW START
  {
    path: '/doc',
    name: 'file-view',
    component: () => import('@/views/pages/collection/doc/FileManager.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
    children: [
      {
        path: 'files',
        name: 'files',
        component: () => import('@/views/pages/collection/doc/DefaultManager.vue'),
      },
      {
        path: 'folder',
        name: 'folder',
        component: () => import('@/views/pages/collection/doc/FolderManager.vue'),
      },
    ],
  },
  {
    path: '/doc/:filter',
    name: 'file-view-filter',
    component: () => import('@/views/pages/collection/doc/FileManager.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'file-view',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/doc/tag/:tag',
    name: 'file-view-tag',
    component: () => import('@/views/pages/collection/doc/FileManager.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'file-view',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/doc/view/:type/:path/:name',
    name: 'view-files',
    component: () => import('@/views/pages/collection/doc/FileManagerHandler.vue'),
    meta: {
      navActiveLink: 'file-view',
    },
  },
  // DOC PREVIEW ENDED

  // COLLECTION START
  // {
  //   path: '/media-gallery',
  //   name: 'media-gallery',
  //   // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
  //   component: () => import('@/views/pages/collection/MediaGallery.vue'),
  // },
  {
    path: '/media-gallery/data',
    name: 'media-gallery',
    component: () => import('@/views/pages/collection/media_gallery/MediaGallery.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Media Gallery',
      breadcrumb: [
        {
          text: 'Media Gallery',
          active: true,
        },
      ],
    },
  },
  {
    path: '/media-gallery/data/details',
    name: 'media-gallery-details',
    component: () => import('@/views/pages/collection/media_gallery/MediaGalleryDetails.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      layout: 'semi',
      navActiveLink: 'media-gallery',
      contentClass: 'ecommerce-application',
    },
  },
  // COLLECTION ENDED

  // REPORT FORMS
  {
    path: '/manhour-report',
    name: 'manhour',
    component: () => import('@/views/pages/collection/form/ManhourReport.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Manhours Report',
      breadcrumb: [
        {
          text: 'Manhours Report',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/e-pdms',
  //   name: 'e-pdms',
  //   component: () => import('@/views/pages/collection/form/e-pdms.vue'),
  //   meta: {
  //     // contentRenderer: 'sidebar-left-detached',
  //     contentClass: 'ecommerce-application',
  //     pageTitle: 'Manhours Report',
  //     breadcrumb: [
  //       {
  //         text: 'E-PDMS Report',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  

  {
    path: '/tags-documents',
    name: 'file-tags',
    component: () => import('@/views/pages/collection/form/e-tags.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'E-PDMS | Tags',
      breadcrumb: [
        {
          text: 'EPDMS',
          active: true,
        },
      ],
    },
  },

  {
    path: '/pedms',
    name: 'file-pedms',
    component: () => import('@/views/pages/collection/form/e-pdms.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'E-PDMS | Document',
      breadcrumb: [
        {
          text: 'EPDMS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/viewer',
    name: 'viewer',
    component: () => import('@/views/pages/collection/form/e-viewer.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'E-Viewer | List',
      breadcrumb: [
        {
          text: 'EPDMS',
          active: true,
        },
      ],
    },
  },
 
  {
    path: '/incident-report',
    name: 'incident',
    component: () => import('@/views/pages/collection/form/IncidentReport.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Incident Report',
      breadcrumb: [
        {
          text: 'Incident Report',
          active: true,
        },
      ],
    },
  },

  // Test
  {
    path: '/modelling',
    name: 'modelling',
    component: () => import('@/views/pages/modelling/3dMesh.vue'),
    // meta: {
    //   // contentRenderer: 'sidebar-left-detached',
    //   navActiveLink: 'location',
    // },
  },
]
