/* eslint-disable eol-last */
/* eslint-disable indent */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VuePdfApp from "vue-pdf-app"
import vSelect from 'vue-select'

import VueGoodTablePlugin from 'vue-good-table'
import axios from "axios"
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePlyr from 'vue-plyr'
import VueExcelViewer from '@uublue/vue-excel-viewer'
import '@uublue/vue-excel-viewer/lib/vue-excel-viewer.css'
import imageViewer from "vue-image-viewer"
import * as VueThreejs from 'vue-threejs'
import VueVideoPlayer from 'vue-video-player'
import VSwitch from 'v-switch-case'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from './router'
import App from './App.vue'
import './assets/css/style.css'
import './assets/vendor/font-awesome/css/all.min.css'
import "vue-pdf-app/dist/icons/main.css"
import 'vue-plyr/dist/vue-plyr.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import 'videojs-flash'
import 'videojs-contrib-hls/dist/videojs-contrib-hls'

// Global Components
import './global-components'
// 3rd party plugins
// import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-slider-component/theme/antd.css'
import 'vue-select/dist/vue-select.css'
import store from './store'

// Axios Mock Adapter
import '@/@fake-db/db'
import Toasted from 'vue-toasted';

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueThreejs)
Vue.use(VueVideoPlayer)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.component("vue-pdf-app", VuePdfApp)
Vue.component('v-select', vSelect)
Vue.use(VSwitch)

Vue.use(VuePlyr, {
    plyr: {},
})

Vue.prototype.$axios = axios

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueGoodTablePlugin)

// Excel Viewer
Vue.use(VueExcelViewer)

// Image Viewer
Vue.use(imageViewer)

Vue.config.productionTip = false
Vue.use(Toasted, {
  duration: 1000
})
Vue.prototype.$baseUrl = "https://dev-api.petronas.myprism.biz"
Vue.prototype.$tokenUrl = "https://webodm.vertikaliti.com/api/token-auth/"

axios.interceptors.response.use(response => response, error => {
    // console.log(error.response.data)
    if (error.response.status === 401) {
      localStorage.removeItem('userData-Core')
      // this.$router.push({ name: 'login' })
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error!',
          icon: 'AlertCircleIcon',
          text: error.response.data.message,
          variant: 'danger',
        },
      }, {
        timeout: 6000,
      })
    } else if (error.response.status === 500) {
      console.log(error.response)
      Vue.swal({
        icon: 'error',
        title: 'Error',
        text: 'Oops. Internal Server Error!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    } else if (error.response.status === 422) {
      console.log(error.response)
      Vue.swal({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
        showConfirmButton: true,
        allowOutsideClick: true,
        // timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }
    // else {
    //   console.log(error.response)
    //   Vue.swal({
    //     icon: 'error',
    //     title: 'Error',
    //     text: 'Oops. Something went wrong!',
    //     showConfirmButton: true,
    //     allowOutsideClick: false,
    //     // timer: 1500,
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //     },
    //     buttonsStyling: false,
    //   })
    // }
    return Promise.reject(error)
  })

// eslint-disable-next-line func-names
Vue.prototype.$successConfirmation = function () {
    this.$swal({
        icon: 'success',
        title: 'Success',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
            confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
    })
}

// eslint-disable-next-line func-names
Vue.prototype.$errorConfirmation = function () {
    this.$swal({
        icon: 'error',
        title: 'Error',
        text: 'Oops. Something went wrong!',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
            confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
    })
}

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')