import mock from '@/@fake-db/mock'
// eslint-disable-next-line object-curly-newline
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  products: [
    {
      id: 1,
      image: require('@/assets/images/gallery/1.png'),
      rating: 3,
      pb: '1563-83 PB',
      title: 'Pengerang Integrated Petroleum',
      address: 'Composition 24-71, Carriage 6, Camera 2, …',
      state: 'Johor',
    },
    {
      id: 2,

      status: 'popular',

      image: require('@/assets/images/gallery/2.png'),

      rating: 4,

      pb: '1563-83 PB',
      title: 'Pengerang Integrated Petroleum',
      address: 'Composition 24-71, Carriage 6, Camera 2, …',
      state: 'Johor',
    },
    {
      id: 3,

      status: 'popular',
      image: require('@/assets/images/gallery/3.png'),

      rating: 5,

      pb: '1563-83 PB',
      title: 'Pengerang Integrated Petroleum',
      address: 'Composition 24-71, Carriage 6, Camera 2, …',
      state: 'Johor',
    },
    {
      id: 4,

      image: require('@/assets/images/gallery/4.png'),

      rating: 2,
      status: 'recent',
      pb: '1563-83 PB',
      title: 'Pengerang Integrated Petroleum',
      address: 'Composition 24-71, Carriage 6, Camera 2, …',
      state: 'Johor',
    },
    {
      id: 5,

      status: 'recent',
      image: require('@/assets/images/gallery/5.png'),

      rating: 2,
      pb: '1563-83 PB',
      title: 'Pengerang Integrated Petroleum',
      address: 'Composition 24-71, Carriage 6, Camera 2, …',
      state: 'Johor',
    },
    {
      id: 6,

      image: require('@/assets/images/gallery/6.png'),
      hasFreeShipping: false,
      rating: 4,

      pb: '1563-83 PB',
      title: 'Pengerang Integrated Petroleum',
      address: 'Composition 24-71, Carriage 6, Camera 2, …',
      state: 'Johor',
    },
    {

      image: require('@/assets/images/gallery/1.png'),
      hasFreeShipping: false,
      rating: 3,

      pb: '1563-83 PB',
      title: 'Pengerang Integrated Petroleum',
      address: 'Composition 24-71, Carriage 6, Camera 2, …',
      state: 'Johor',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/medias/videos').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', sortBy = 'featured', perPage = 9, page = 1 } = config.params

  const queryLowered = q.toLowerCase()

  const filteredData = data.products.filter(product => product.title.toLowerCase().includes(queryLowered))

  let sortDesc = false
  const sortByKey = (() => {
    if (sortBy === 'popular') {
      sortDesc = true
      return 'status'
    }
    if (sortBy === 'latest') {
      return 'status'
    }
    sortDesc = true
    return 'id'
  })()

  const sortedData = filteredData.sort(sortCompare(sortByKey))
  if (sortDesc) sortedData.reverse()

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

  return [
    200,
    {
      videos: paginatedData,
      total: filteredData.length,
    },
  ]
})
